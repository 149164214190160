export const RadioOpMode = {
    A: 0,
    B: 1,
    G: 2,
    N: 3,
    BGN: 4,
    AC: 5,
    ANAC: 6
}

export const RadioChannelExtension = {
    UPPER: 0,
    LOWER: 1
}

export const RadioFrequency = {
    _2_4GHz: 0,
    _5GHz: 1
}

export const RadioBandwidth = {
    _20MHz: 0,
    _40MHz: 1,
    _20_40MHz:2,    
    _80MHz: 3,
    _160MHz: 4,

}

export const WirelessMode = {
    AP: 0,
    CLIENT: 1,
}

export const WirelessCipher = {
    NONE: 0,
    WEP: 1,
    WPA: 2,
    WPA2: 3,
    WPA_WPA2: 4,
    WPA3: 5,
    WPA2_WPA3: 6
}

export const WirelessCrypto = {
    NONE: 0,
    TKIP: 1,
    AES: 2,
    TKIP_AES: 3,
}